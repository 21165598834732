<template>
  <div class="action__inner">
    <a-form :model="dataSource" :rules="rules" @finish="sendTransfer" name="trading" ref="trading">
      <a-form-item
        name="writeOffAccount"
        class="input__wrapper"
        :label="i18n.t('start.writeOffAccount')"
        v-if="tradingAccount.attributes.mt5_account_data.kind !== 'demo'"
      >
        <a-input :value="writeInAccount" size="large" type="text" :disabled="true" style="width: 100%" />
      </a-form-item>
      <a-form-item name="writeInAccount" class="input__wrapper" :label="i18n.t('start.writeInAccount')">
        <a-input :value="writeOffAccount" size="large" type="text" :disabled="true" style="width: 100%" />
      </a-form-item>

      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
        >
          <template #addonAfter>
            <p class="action__title">USDT</p>
          </template>
        </a-input>
      </a-form-item>
      <sdButton
        class="action__button"
        size="large"
        :disabled="isLoading || errorAmount"
        type="primary"
        htmlType="submit"
        raised
      >
        {{ actionType == 'trading-deposit' ? i18n.t('start.deposit') : i18n.t('start.withdrawal') }}
      </sdButton>
      <!--<p class="action__more-info" @click="changePayInfo">
              <sdFeatherIcons type="info" size="20" /> {{ i18n.t('start.moreInfo') }}
            </p>-->
    </a-form>
  </div>
  <!--<div class="action__inner" v-if="visiblePayHint">
          <p class="action__info">
            {{ i18n.t('start.actionTransferHintOne', accId == tokenAccount.id ? 1 : 2) }}
          </p>
          <p class="action__info" v-if="accId == tokenAccount.id">
            {{ i18n.t('start.actionTransferHintTwo') }}
          </p>
        </div>-->
</template>

<script>
import { computed, reactive, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const TradingModal = {
  name: 'TradingModal',
  props: { error: Boolean, tradingAccount: Object, actionType: String },
  emits: ['dataNotSended', 'dataSended'],
  setup(props, { emit }) {
    const i18n = reactive(useI18n());
    const { state, dispatch } = useStore();

    const dataSource = ref({
      amount: '',
    });
    const writeInAccount = ref('');
    const writeOffAccount = ref('');
    const errorAmount = ref(false);

    const isLoading = computed(() => state.accounts.fileLoading);

    const mainAccount = computed(() => state.accounts.mainAccount);

    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }
      let max = 100000;
      switch (true) {
        case props.actionType == 'trading-deposit' && props.tradingAccount.attributes.mt5_account_data.kind !== 'demo':
          max = +mainAccount.value.attributes.amount;
          break;
        case props.actionType == 'trading-widthdrawal' &&
          props.tradingAccount.attributes.mt5_account_data.kind !== 'demo':
          max = +props.tradingAccount.attributes.amount;
          break;
      }

      const validate = { min: 10, max };

      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(
          i18n.t('messages.errorAmount', validate, dataSource.value.amount > validate.max ? 2 : 1) + ' USDT',
        );
      } else {
        errorAmount.value = false;
        return Promise.resolve();
      }
    };

    const rules = reactive({
      amount: [
        {
          trigger: ['blur'],
          type: 'number',
          validator: amountValidation,
        },
      ],
    });

    const sendTransfer = async () => {
      if (props.tradingAccount.attributes.mt5_account_data.kind !== 'demo') {
        if (props.actionType == 'trading-deposit') {
          dataSource.value.account_from_id = mainAccount.value.id;
          dataSource.value.account_to_id = props.tradingAccount.id;
        } else {
          dataSource.value.account_from_id = props.tradingAccount.id;
          dataSource.value.account_to_id = mainAccount.value.id;
        }
        await dispatch('sendTransfer', dataSource.value);

        if (props.error) {
          emit('dataNotSended');
        } else {
          emit('dataSended');
        }
      } else {
        await dispatch('topUpDemoAccount', { amount: dataSource.value.amount, id: props.tradingAccount.id });
        if (state.tradingAccounts.error) {
          emit('dataNotSended');
        } else {
          emit('dataSended');
        }
        // emit('dataSended');
      }
    };

    watchEffect(() => {
      const main = mainAccount.value.attributes;
      const trading = props.tradingAccount.attributes;
      const isDeposit = props.actionType == 'trading-deposit';

      const accounts = {
        main: i18n.t('start.inputAccount', {
          name: i18n.t('start.name' + main.kind),
          amount: main.amount,
        }),
        trading: i18n.t('start.inputAccount', {
          name: trading.mt5_account_data.name,
          amount: trading.amount,
        }),
      };

      if (isDeposit) {
        writeInAccount.value = accounts.main;
        writeOffAccount.value = accounts.trading;
      } else {
        writeInAccount.value = accounts.trading;
        writeOffAccount.value = accounts.main;
      }
    });

    return {
      i18n,
      dataSource,
      errorAmount,
      isLoading,
      mainAccount,
      rules,
      writeInAccount,
      writeOffAccount,
      sendTransfer,
    };
  },
};
export default TradingModal;
</script>

<style></style>
